.fail > .ant-select-selector {
  background-color: rgba(245, 97, 34, 0.5) !important;
}

.pass > .ant-select-selector {
  background-color: rgba(93, 164, 35, 0.5) !important;
}

/* .clarification > .ant-select-selector {
  background-color: yellow !important;
} */

.excellent > .ant-select-selector {
  background-color: rgba(93, 164, 35, 0.75) !important;
}
.pass-pcg > .ant-select-selector {
  background-color: rgba(93, 164, 35, 0.75) !important;
}
.good > .ant-select-selector {
  background-color: rgba(93, 164, 35, 0.5) !important;
}
.acceptable > .ant-select-selector {
  background-color: rgba(93, 164, 35, 0.25) !important;
}
.minor > .ant-select-selector {
  background: rgba(245, 97, 34, 0.25) !important;
}
.major > .ant-select-selector {
  background: rgba(245, 97, 34, 0.5) !important;
}
.unacceptable > .ant-select-selector {
  background: rgba(245, 97, 34, 0.75) !important;
}
.withdrawn > .ant-select-selector {
  background: rgba(245, 97, 34, 0.75) !important;
}
