/*status: WAIT*/
.ant-steps-item-wait .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}

/*status: FINISH */
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #5da423;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #5da423;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background-color: #5da423;
}

/*status: PROCESS*/
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background-color: rgba(0, 0, 0, 0.45);
}

/*status: ERROR*/
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail:after {
  background-color: rgba(0, 0, 0, 0.45);
}

.black-icon > .ant-steps-item-container > .ant-steps-item-icon {
  border-color: #000000CC;
}

.black-icon > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  color: #000000CC;
}

.black-text > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #000000CC;
}

.ant-steps-item {
  padding-bottom: 16px !important;
}

.ant-steps-item:last-of-type {
  padding-bottom: 0 !important;
}