/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

/* ant design styles */
@import "../node_modules/antd/dist/antd.min.css";

.ant-select-item-option-content {
  text-overflow: unset;
  white-space: normal;
}

.ant-col {
  height: 100%;
}

.progress-center .ant-progress-outer {
  display: flex;
  align-items: center;
}
