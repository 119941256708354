.ccg-tree {
  display: flex;
  flex-flow: row wrap;

  > * {
    flex: 0 33%;
    padding-bottom: 32px !important;
  }

  @media screen and (max-width: 1100px) {
    > * {
      flex: 0 50%;
    }
  }

  @media screen and (max-width: 875px) {
    > * {
      flex: 0 100%;
    }
  }

  .ant-tree-switcher {
    display: none !important;
  }
}
