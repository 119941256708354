.eprocurement-modal > .ant-modal-content {
  border: 1px solid grey;
}

.eprocurement-modal > .ant-modal-content > .ant-modal-body {
  padding: 24 !important;
}

.eprocurement-card > .ant-card-body {
  padding: 0 !important;
}

.application-card > .ant-card-body {
  padding-top: 0 !important;
}
